<template>
  <div class="hot-patent">
    <img class="hp-title" :src="require('../../../assets/image/index/index_b.png')" />
    <div class="hp-body">
      <div class="hp-label">
        <div
          @click="setSelectLabel(index)"
          :class="{'hp-label-select':selectLabelId===index}"
          class="hp-label-text" v-for="(item,index) in data&&data.labels" :key="index">
          {{ item.name }}
        </div>
        <div class="hp-label-all" @click="goGoodList">查看全部专利></div>
      </div>

      <div class="hp-list">
        <div class="hp-main"
             @click="goGoodDetail(item.id)"
             :key="item.id"
             v-for="item in data&&data.labels.length>0&&data.labels[selectLabelId].objs.length>10?data.labels[selectLabelId].objs.slice(0,10):data.labels[selectLabelId].objs">
          <image-loader :width="194" :height="196" :src="item.pic" :mode="'cover'" />
          <div class="hp-main-describe ellipse-2">{{ item.name }}</div>
          <div class="hp-main-price">
            <div class="hp-main-price-fontA ellipse-1">￥{{ item.price }}</div>
            <div class="hp-main-price-fontB ellipse-1">
              {{ data && data.labels && selectLabelId !== -1 && data.labels[selectLabelId].name }}
            </div>
          </div>
          <div class="hp-main-buy">
            <div class="hp-main-buy-button">立即购买</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hotPatent",
  props: {
    data: Object
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectLabelId = 0;
        }
      }
    }
  },
  data() {
    return {
      selectLabelId: -1
    };
  },
  methods: {
    setSelectLabel(id) {
      this.selectLabelId = id;
    },
    goGoodDetail(id) {
      this.$router.push({
        path: "/home/good",
        query: {
          goodId: id
        }
      });
    },
    goGoodList() {
      this.$router.push({
        path: "/home/goodsList",
        query: {
          patentType: 0
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.hot-patent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

.hp-title {
  width: 413px;
  height: 52px;
  margin-top: 50px;
}

.hp-body {
  width: 1200px;
  display: flex;
  flex-direction: column;
}

.hp-label {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E5E8ED;
  height: 35px;
  margin-top: 20px;
  justify-content: center;
}

.hp-label-text {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  width: 200px;
  display: flex;
  justify-content: center;
  height: 35px;
  cursor: pointer;
}

.hp-label-select {
  color: rgba(24, 144, 255, 1);
  border-bottom: 1px solid rgba(24, 144, 255, 1);
}

.hp-label-all {
  font-size: 16px;
  font-weight: 400;
  color: #1890FF;
  padding-left: 50px;
  cursor: pointer;
}

.hp-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.hp-main {
  width: 216px;
  border-radius: 8px;
  border: 1px solid #E5E8ED;
  margin-top: 30px;
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
}

.hp-main:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.08);
}

.hp-main:hover > .hp-main-buy {
  height: 45px;
  visibility: visible;
}

.hp-main-img {
  width: 100%;
  height: 196px;
}

.hp-main-describe {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin-top: 10px;
  height: 40px;
}

.hp-main-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 45px;
}

.hp-main-price-fontA {
  font-size: 24px;
  font-weight: 500;
  color: #FF5200;
}

.hp-main-price-fontB {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 10px;
}

.hp-main-buy {
  height: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 10px;
  background-color: #FFFFFF;
  visibility: hidden;
  transition: height 0.5s;
  overflow: hidden;
}

.hp-main-buy-button {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  background-color: rgba(24, 144, 255, 1);
  width: 100%;
  border-radius: 4px;
  border: 1px solid #1890FF;
}

</style>
